<template>
  <div class="lotteries-schedules-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :sm="24">
        <h2 class="list-title">
          <a-icon type="folder" class="list-icon" />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :sm="24" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="16"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
          }"
        >
          <a-col :lg="18" :md="18" :sm="20" :xs="16">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            :lg="6"
            :md="6"
            :sm="4"
            :xs="8"
            :style="{ textAlign: 'right' }"
            v-if="canCreate"
          >
            <router-link
              :to="{ name: 'admin.lotteries-closing-schedules.create' }"
            >
              <a-button type="default" ghost icon="plus" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchSchedules"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          v-bind="!isMobile && { scroll: { x: true } }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
          :customRow="handleCustomRow"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";
import _ from "lodash";
export default {
  components: { StandardTable },
  name: "ScheduleList",
  metaInfo: {
    title: adminPageTitle("Cierre Loterías"),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      labels: labels.lotteriesSchedules,
      search: "",
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("lotteries", ["fetchSchedules", "deleteSchedule"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "delete",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteSchedule(id);
            this.$message.success(labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                labels.deleteError
            );
          }
        },
      });
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
      ]);
    },
    canCreate() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
      ]);
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text)
              .format("DD MMM YYYY")
              .split(" ")
              .map((world) => this.$options.filters.ucfirst(world))
              .join(" ");
          },
        },
        {
          title: this.labels.table.columns.lotteries,
          dataIndex: "lotteries",
          key: "lotteries",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            const hasMore = text.length > 2;
            const firstTwo = _.cloneDeep(text).slice(0, 2);
            const rest = _.cloneDeep(text).slice(2);
            return (
              <span>
                {firstTwo.map((item) => item.name).join(", ")}
                {hasMore ? " y " : ""}
                {hasMore ? (
                  <a-tooltip
                    title={rest.map((item) => item.name).join("\n\n")}
                    placement="bottom"
                  >
                    <a-tag color="blue" style="margin-left: 5px">
                      +{rest.length}
                    </a-tag>
                  </a-tooltip>
                ) : null}
              </span>
            );
          },
        },
        {
          title: this.labels.table.columns.title,
          dataIndex: "title",
          key: "title",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: () => (
            <div>
              <font-awesome-icon
                icon={["fas", "rotate"]}
                class="ant-icon"
                style={{ marginRight: "5px" }}
              />
              {this.labels.table.columns.annually.title}
            </div>
          ),
          dataIndex: "annually",
          key: "annually",
          sorter: true,
          align: "center",
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={text ? "green" : "red"}>
                {text
                  ? this.labels.table.columns.annually.yes
                  : this.labels.table.columns.annually.no}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (_, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.lotteries-closing-schedules.edit",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="delete"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.id)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.lotteriesSchedule,
          dataIndex: "lotteriesSchedules",
          key: "lotteriesSchedules",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_text, record, index) => {
            // Card item design
            const color = record.annually ? "#28a745" : "#f50";
            const hasMore = record.lotteries.length > 2;
            const firstTwo = _.cloneDeep(record.lotteries).slice(0, 2);
            const rest = _.cloneDeep(record.lotteries).slice(2);
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(
                          record.date,
                          DATE_FORMAT.SERVER_DATE
                        ).format(DATE_FORMAT.MOMENT_DAY_MONTH_YEAR)
                      )}
                    </span>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-title">
                      <span>{record.title}</span>
                    </div>
                    <div class="list-item-head-content-lotteries">
                      <span>
                        {firstTwo.map((item) => (
                          <span class="w-100">- {item.name}</span>
                        ))}
                        {hasMore ? (
                          <a-tooltip placement="bottom">
                            <template slot="title">
                              {rest.map((item) => (
                                <span class="w-100" title={item.name}>
                                  - {item.name}
                                </span>
                              ))}
                            </template>
                            <a-tag color="blue" style="margin-left: 5px">
                              +{rest.length}
                            </a-tag>
                          </a-tooltip>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-annually">
                      <span class="text-label">
                        {this.labels.table.columns.annually.title}
                      </span>
                      <a-tag color={record.annually ? "green" : "red"}>
                        <font-awesome-icon
                          icon={["fas", "rotate"]}
                          class="anticon"
                        />{" "}
                        {record.annually
                          ? this.labels.table.columns.annually.yes
                          : this.labels.table.columns.annually.no}
                      </a-tag>
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <router-link
                        class="edit-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "admin.lotteries-closing-schedules.edit",
                          params: { id: record.id },
                        }}
                        title={this.labels.table.columns.action.edit}
                      >
                        {this.labels.table.columns.action.edit}
                      </router-link>
                      {this.canDelete && (
                        <a-button
                          class="delete-button ant-btn ant-btn-danger"
                          onClick={() => this.confirmDelete(record.id)}
                          title={this.labels.table.columns.action.delete}
                          type="danger"
                        >
                          {this.labels.table.columns.action.delete}
                        </a-button>
                      )}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.lotteries-schedules-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 60%;
        width: 60%;
        overflow: hidden;
        &-title {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-lotteries {
          span {
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 15%;
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-annually {
          width: 100%;
          span {
            font-size: 12px;
            font-weight: 400;
            &.text-label {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .ant-tag {
            padding: 0;
            margin: 0;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .edit-button,
        .delete-button {
          width: 100%;
        }
      }
    }
  }
}

.w-100 {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 3px;
}
</style>
